import { responseApi } from 'use-hook-api';

const uid = process.env.NEXT_PUBLIC_UID;
const emailURL = process.env.NEXT_PUBLIC_EMAIL_URL;

export const getAddressApi = (search) => {
  return responseApi(
    'https://places.googleapis.com/v1/places:searchText',
    'post',
    {
      textQuery: search,
    },
    {
      'X-Goog-Api-Key': process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
      'X-Goog-FieldMask': 'places.formattedAddress',
    }
  );
};

export const sendEmailApi = (data) => {
  return responseApi(`${emailURL}api/email/`, 'post', data, { uid });
};

// export const getAddressApi = (search) => {
//   return responseApi(
//     `${mapURL}search?format=json&q=${search}&countrycodes=gb,nl,de,fr,dk&limit=10`,
//     'get'
//   );
// };

// `${mapURL}search?format=json&q=${searchTerm}&countrycodes=nl,gb,de,fr,ch&limit=5`

// https://places.googleapis.com/v1/places/GyuEmsRBfy61i59si0?fields=addressComponents&key=YOUR_API_KEY
