import { useState, useEffect, useRef } from 'react';

export default function useDebounce(debouncedCallback, delay = 500) {
    const [text, setText] = useState('');
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        } else {
            const handler = setTimeout(() => {
                if (typeof debouncedCallback === 'function')
                    debouncedCallback();
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }
    }, [text, delay]);

    return [text, setText];
};



