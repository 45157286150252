import { sendEmailApi } from '@api/emailApi';
import Spinner from '@common/feedback/Spinner';
import { company } from '@components/config/company/Details';
import { Phone } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CelebrationIcon from '@mui/icons-material/Celebration';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LightModeIcon from '@mui/icons-material/LightMode';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useDebounce from '@utils/useDebounce';
import dayjs from 'dayjs';
import { getAddressApi } from 'lib/quote';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { useApi, useSetFeedback } from 'use-hook-api';
import { ourservices, stepsToBook } from './data';
import PhoneInput from '@common/phoneInput/PhoneInput';
import { useMediaQuery } from '@mui/material';
import MaterialPhone from '@common/phoneInput/PhoneInput';

function Home() {
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [state, setState] = useState({
    name: '',
    email: '',
    country: 'gb',
    phone: '',
    time: '',
    from: null,
    to: null,
    coach: '16 seat',
  });
  const setFeedback = useSetFeedback();
  const [getAddress, { data: addressData, loading: addressLoader }] = useApi({
    both: false,
  });
  const matches = useMediaQuery('(min-width:600px)');

  const [emailFun, { loading: emailLoader }] = useApi({});

  const setFromDebouncedCallback = () => {
    if (fromText !== '') {
      getAddress(getAddressApi(`${fromText}`));
    }
  };
  const setToDebouncedCallback = () => {
    if (toText !== '') {
      getAddress(getAddressApi(`${toText}`));
    }
  };

  const [fromText, setFromText] = useDebounce(setFromDebouncedCallback);
  const [toText, setToText] = useDebounce(setToDebouncedCallback);

  const onStateChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = false;
    if (state.name === '') {
      err = true;
      setFeedback(['All highlighted fields are required', 'error']);
    }
    if (state.phone === '') {
      err = true;
      setFeedback(['All highlighted fields are required', 'error']);
    }
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(state.email)) {
      err = true;
      setFeedback(['Please enter a valid email', 'error']);
    }
    if (state.from === null) {
      err = true;
      setFeedback(['All highlighted fields are required', 'error']);
    }
    if (state.to === null) {
      err = true;
      setFeedback(['All highlighted fields are required', 'error']);
    }
    if (!err) {
      const CompanyEmailData = {
        support_email: company.company_email,
        recipient: [company.company_email],
        message: `A new query has been submitted on the website quote form.<br/>Please find the details below.<br/>Name: ${state.name}<br/>Phone: ${state.phone}<br/>Email: ${state.email}<br/>Date: ${startDate}<br/>Time: ${state.time}<br/>Pickup: ${state?.from?.formattedAddress}<br/>Destination: ${state?.to?.formattedAddress}`,
        subject: `${company.name} Quote Form`,
        rich_text: true,
      };

      const UserEmailData = {
        support_email: company.company_email,
        recipient: [state.email],
        message: `Thank you <b>${state.name}</b> for contacting <b>${company.name}</b>. <br/>We have received your query and will get back to you as soon as possible. <br/> Regards, <br/> ${company.name} Team`,
        subject: `${company.name} Quote Form`,
        rich_text: true,
      };
      emailFun(sendEmailApi(CompanyEmailData), () => {
        emailFun(
          sendEmailApi(UserEmailData),
          () => {
            setFeedback('We will get back to you asap!', 'success');
            setState({
              name: '',
              email: '',
              country: 'gb',
              phone: '44',
              time: dayjs(),
              from: null,
              to: null,
              coach: '16 seat',
            });
          },
          (error) => {
            setFeedback(['Something went wrong', 'error']);
          }
        );
      });
    }
  };
  console.log(state);

  return (
    <div>
      <div className="bg-HomeBanner bg-cover bg-right md:bg-center bg-no-repeat">
        <div className="md:bg-blackDiagonal bg-cover bg-black/70 text-white pt-32 md:pt-40 pb-20">
          <div className="container md:px-0 px-5">
            <div className="flex flex-col lg:flex-row justify-between items-center gap-10">
              <div>
                <div className="transparentWhite text-4xl md:text-8xl font-extrabold">
                  Book Your
                </div>
                <div className="md:text-8xl text-4xl font-extrabold">
                  Bus Easy
                </div>
                <div className="my-10 md:text-xl text-white/80 font-light">
                  Where Travel Plans Simplify!
                </div>
                <div className="flex">
                  <Link href={'/services'}>
                    <a
                      className="gradientBg px-8 py-3 rounded-xl"
                      aria-label="link to services page"
                    >
                      Learn More
                      <ArrowRightAltIcon className="ml-2" />
                    </a>
                  </Link>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="bg-white max-w-[550px] min-h-[570px] text-black py-6 px-6 rounded-lg">
                  <h2 className="text-xl font-medium">
                    Fast & Easy Quote Form
                  </h2>
                  <div className="text-gray-500 font-light text-lg py-3 border-b">
                    We offer a full range of bus and coach hire with driver
                    services
                  </div>
                  <div className="bg-gray-100 px-4 py-2 rounded-lg my-4 flex items-center gap-7">
                    <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                      <AccountCircleIcon />
                    </div>
                    <div className="flex flex-col w-full text-gray-500">
                      <div>Name</div>
                      <div className="text-black text-[17px]">
                        <TextField
                          value={state.name}
                          onChange={(e) => {
                            onStateChange('name', e.target.value);
                          }}
                          variant="standard"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-2 rounded-lg my-4 flex items-center gap-7">
                    <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                      <EmailIcon />
                    </div>
                    <div className="flex flex-col w-full text-gray-500">
                      <div>Email</div>
                      <div className="text-black text-[17px]">
                        <TextField
                          value={state.email}
                          onChange={(e) => {
                            onStateChange('email', e.target.value);
                          }}
                          variant="standard"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-2 rounded-lg my-4 flex items-center gap-7">
                    <div className="hidden w-10 h-10 bg-white rounded-lg md:flex justify-center items-center">
                      <Phone />
                    </div>
                    <div className="flex flex-col w-full text-gray-500">
                      <div>Mobile</div>
                      <div className="text-black text-[17px]">
                        {/* <PhoneInput
                          preferredCountries={['gb']}
                          value={state?.phone?.toString()}
                          onPhoneChange={(valid, num, countryObj, final) => {
                            onStateChange('phone', num)
                          }}
                          // style={{ padding: matches ? '0px 0px 0px 0px' : '10px 10px 14px 10px' }}
                          showLabel={false}
                        /> */}

                        <MaterialPhone
                          style={{ width: '100%' }}
                          country={state.country}
                          onChange={(value, d) => {
                            setState((prevState) => ({
                              ...prevState,
                              phone: value,
                            }));
                          }}
                          value={state?.phone}
                          countryCodeEditable={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-5 my-4">
                    <div className="bg-gray-100 px-4 py-2 rounded-lg flex items-center gap-7">
                      <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                        <EventIcon />
                      </div>
                      <div className="flex flex-col w-full text-gray-500">
                        <div>Date</div>
                        <div className="w-full">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              type="date"
                              disablePast
                              fullWidth
                              format="DD/MM/YYYY"
                              defaultValue={dayjs(new Date())}
                              name="startDate"
                              onChange={(date) => setStartDate(date)}
                              className="!text-black w-full"
                              slotProps={{ textField: { variant: 'standard' } }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 px-4 py-2 rounded-lg flex items-center gap-7">
                      {/* <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                        <ScheduleIcon />
                      </div> */}
                      <div className="flex flex-col w-full ">
                        <div>Time</div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              minTime={moment(new Date())}
                              fullWidth
                              ampm={false}
                              slotProps={{ textField: { variant: 'standard' } }}
                              value={state.time || dayjs()}
                              className="text-black"
                              onChange={(newValue) =>
                                setState((prev) => ({
                                  ...prev,
                                  time: newValue,
                                }))
                              }
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-2 rounded-lg my-4 flex items-center gap-7">
                    <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                      <RoundaboutLeftIcon />
                    </div>
                    <div className="flex flex-col w-full text-gray-500">
                      <div>From</div>
                      <div className="text-black text-[17px]">
                        <Autocomplete
                          clearOnEscape={true}
                          autoComplete={false}
                          value={state?.from}
                          inputValue={fromText}
                          onInputChange={(event, newInputValue) => {
                            setFromText(newInputValue);
                          }}
                          options={addressData?.places || []}
                          getOptionLabel={(option) => option?.formattedAddress}
                          filterOptions={(x) => x}
                          id="combo-box-demo"
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                          onChange={(event, newValue) => {
                            setState((prev) => ({ ...prev, from: newValue }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-2 rounded-lg my-4 flex items-center gap-7">
                    <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                      <RoundaboutRightIcon />
                    </div>
                    <div className="flex flex-col w-full text-gray-500">
                      <div>To</div>
                      <div className="text-black text-[17px]">
                        <Autocomplete
                          disabled={!state.from}
                          value={state?.to}
                          inputValue={toText}
                          onInputChange={(event, newInputValue) => {
                            setToText(newInputValue);
                          }}
                          filterOptions={(x) => x}
                          getOptionLabel={(option) => option.formattedAddress}
                          id="combo-box-demo"
                          options={addressData?.places || []}
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                          onChange={(event, newValue) => {
                            setState((prev) => ({ ...prev, to: newValue }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-2 rounded-lg my-4 flex items-center gap-7">
                    <div className="w-10 h-10 bg-white rounded-lg flex justify-center items-center">
                      <AirportShuttleIcon />
                    </div>
                    <div className="flex flex-col w-full text-gray-500">
                      <div>Coach</div>
                      <div className="text-black text-[17px]">
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant="standard"
                          value={state.coach || ''}
                          name="coach"
                          onChange={(e) => {
                            onStateChange('coach', e.target.value);
                          }}
                          className="text-black"
                        >
                          <MenuItem value={'16 seat'}>16 Seat</MenuItem>
                          <MenuItem value={'33 seat'}>33 Seat</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={emailLoader}
                    className="gradientBg px-8 py-4 text-[18px] rounded-xl w-full mt-5 text-center text-white"
                  >
                    {emailLoader ? <Spinner /> : 'Get a Free Quote'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container md:px-0 px-10 grid md:grid-cols-2 lg:grid-cols-4 gap-4 py-20 ">
        <div className="shadow-xl rounded-xl hover:bg-suppBg hover:bg-black/80 text-black flex items-center justify-center flex-col py-10 px-5 transition-all hover:text-white bg-gray-100">
          <div className="flex ">
            <div className="bg-gradient-to-r from-blue-400 to-blue-900 rounded-3xl p-4">
              <FlightTakeoffIcon className="text-white text-6xl" />
            </div>
          </div>
          <div className="text-3xl my-5 font-bold text-center">
            Airport Transfers
          </div>
          <div className="text-center font-light">
            Start your holiday stress-free with our professional
            door-to-departure lounge drivers.
          </div>
        </div>
        <div className="shadow-xl rounded-xl hover:bg-suppBg hover:bg-black/80 text-black flex items-center justify-center flex-col py-10 px-5 transition-all hover:text-white bg-gray-100">
          <div className="flex ">
            <div className="bg-gradient-to-r from-blue-400 to-blue-900 rounded-3xl p-4">
              <SportsCricketIcon className="text-white text-6xl" />
            </div>
          </div>
          <div className="text-3xl my-5 font-bold text-center">
            Sporting Events
          </div>
          <div className="text-center font-light">
            Enjoy stress-free sporting events with our professional
            door-to-venue transfer service.
          </div>
        </div>
        <div className="shadow-xl rounded-xl hover:bg-suppBg hover:bg-black/80 text-black flex items-center justify-center flex-col py-10 px-5 transition-all hover:text-white bg-gray-100">
          <div className="flex ">
            <div className="bg-gradient-to-r from-blue-400 to-blue-900 rounded-3xl p-4">
              <LightModeIcon className="text-white text-6xl" />
            </div>
          </div>
          <div className="text-3xl my-5 font-bold text-center">Day Trips</div>
          <div className="text-center font-light">
            Enjoy stress-free day trips with our professional
            door-to-destination transfer service.
          </div>
        </div>
        <div className="shadow-xl rounded-xl hover:bg-suppBg hover:bg-black/80 text-black flex items-center justify-center flex-col py-10 px-5 transition-all hover:text-white bg-gray-100">
          <div className="flex ">
            <div className="bg-gradient-to-r from-blue-400 to-blue-900 rounded-3xl p-4">
              <CelebrationIcon className="text-white text-6xl" />
            </div>
          </div>
          <div className="text-3xl my-5 font-bold text-center">
            Concerts/Parties
          </div>
          <div className="text-center font-light">
            Enjoy stress-free concert and party trips with our professional
            door-to-venue transfer service.
          </div>
        </div>
      </div>
      <div className="md:relative container md:px-0 px-10 py-20">
        <div>
          <div className="transparentBlack text-6xl font-extrabold">
            About Us
          </div>
          <div className="md:text-6xl text-3xl my-5 font-bold">
            Luxury Travel at an
            <br />
            Affordable Price
          </div>
          <div className="max-w-[560px]">
            {company.name} Travel is a budget-friendly travel service that
            offers comfortable and reliable transportation options for
            passengers. Catering to a wide range of travelers, from daily
            commuters to occasional tourists, {company.name} Travel ensures a
            pleasant journey with clean, well-maintained buses, friendly
            drivers.
            <br />
            <br />
            it provides great value without compromising on quality or safety,
            making it an ideal choice for those seeking travel.
          </div>
        </div>
        <div className="flex  md:absolute right-0 top-40">
          <Image
            src="/assets/images/8965.png"
            alt="about1"
            className="rounded-l-3xl"
            width={200}
            height={528}
          />
          <Image
            src="/assets/images/1956.png"
            alt="about2"
            width={200}
            height={528}
          />
          <Image
            src="/assets/images/1986.png"
            alt="about3"
            className="rounded-r-3xl"
            width={200}
            height={528}
          />
        </div>
      </div>
      <div className="bg-black/90 bg-bgDesign2">
        <div className=" pt-20 pb-10 container md:px-0 px-10">
          <div className="flex md:items-start md:justify-start items-center justify-center">
            <Link href={'/about-us'}>
              <a
                className="gradientBg px-8 py-3 rounded-xl text-white"
                aria-label="link to services page"
              >
                Learn More
                <ArrowRightAltIcon className="ml-2" />
              </a>
            </Link>
          </div>
          <div className="mt-20 flex items-center justify-between  ">
            <Image
              src="/assets/images/client1.png"
              alt="Client1"
              width={150}
              height={90}
            />
            <Image
              src="/assets/images/client2.png"
              alt="Client1"
              width={300}
              height={50}
            />
            <Image
              src="/assets/images/client3.png"
              alt="Client1"
              width={200}
              height={90}
            />
            <Image
              src="/assets/images/client4.png"
              alt="Client1"
              width={130}
              height={110}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="container py-20 md:px-0 px-5">
          <div className="transparentBlack text-center  text-6xl font-extrabold">
            Our Services
          </div>
          <div className="text-5xl text-center my-4 font-bold">
            Book Your Bus
          </div>
          <div className="grid md:grid-cols-3 gap-10 mt-20">
            {ourservices.map((item, index) => (
              <div
                key={index}
                className={`shadow-xl relative flex items-center md:items-start md:justify-starr rounded-xl  mt-20 justify-center flex-col pt-14 pb-10 px-5 
                   hover:bg-suppBg hover:bg-black/80 hover:text-white transition-all
                   bg-gray-200
                }`}
              >
                <div className="flex ">
                  <div
                    className={` md:absolute left-0 -top-14 
                      
                         bg-gradient-to-r from-blue-400 to-blue-900 rounded-3xl p-4
                         bg-primary
                    }`}
                  >
                    {item.icon}
                  </div>
                </div>
                <div className="text-3xl my-5 font-bold ">{item.title}</div>
                <div className=" font-light">{item.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-Reviews bg-cover bg-center bg-no-repeat">
        <div className="md:bg-blackDiagonal bg-black/80 text-white py-20">
          <div className="container md:space-y-0 space-y-10 flex md:flex-row flex-col md:px-0 px-10 items-center justify-between">
            <div>
              <div className="transparentWhite text-4xl md:text-7xl font-extrabold">
                Testimonials
              </div>
              <div className="md:text-7xl text-4xl font-extrabold">
                What Clients Say
              </div>
              <div className="my-10 text-xl font-light">
                Discover firsthand testimonials from our satisfied clientele,
                <br />
                who consistently commend our service excellence and dedication
                <br />
                to exceeding expectations.
              </div>
              <div className="flex">
                <Link href={'/services'}>
                  <a
                    className="gradientBg px-8 py-3 rounded-xl"
                    aria-label="link to services page"
                  >
                    Our Services
                  </a>
                </Link>
              </div>
            </div>
            <div className="md:px">
              <Rating
                name="read-only"
                value={5}
                readOnly
                className="md:text-4xl text-2xl"
              />
              <div className="text-lg text-white/70 font-thin">
                Exceptional Service! From start to finish, our experience with
                <br />
                Dynamic coach was outstanding. The booking process was seamless,
                <br />
                and their team was incredibly helpful in tailoring our trip to
                <br />
                suit our needs. The bus was clean, comfortable, and arrived
                <br />
                right on time.
              </div>
              <div className="mt-5 flex gap-5 items-center">
                <Image
                  alt="clientImage"
                  src="/assets/images/customer.png"
                  width={70}
                  height={70}
                />
                <div>
                  <div className="font-extrabold text-xl">Elanor</div>
                  <div className="font-thin text-sm">Customer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary/90">
        <div className="container flex md:flex-row flex-col md:space-y-0 space-y-10 items-center justify-between md:px-40 px-5 py-10">
          <div className="text-white text-center">
            <div className="transparentWhite text-4xl ">6</div>
            <div>Buses</div>
          </div>
          <div className="text-white text-center">
            <div className="transparentWhite text-4xl ">12</div>
            <div>Team</div>
          </div>
          <div className="text-white text-center">
            <div className="transparentWhite text-4xl ">100+</div>
            <div>Projects Done</div>
          </div>
          <div className="text-white text-center">
            <div className="transparentWhite text-4xl ">10+</div>
            <div>Experiance</div>
          </div>
          <div className="text-white text-center">
            <div className="transparentWhite text-4xl ">31</div>
            <div>Achievments</div>
          </div>
        </div>
      </div>
      <div>
        <div className="container md:px-20 px-5 py-20">
          <div className="transparentBlack text-center  text-4xl font-extrabold">
            How We Work
          </div>
          <div className="text-5xl text-center my-4 font-bold">
            Steps for Booking a Bus
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-20 mt-20">
            {stepsToBook.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center justify-center"
              >
                <div className="flex items-center justify-center">
                  <div className="gradientBg rounded-2xl p-3">{item.icon}</div>
                </div>
                <div className="font-bold  my-5 text-xl">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="py-20 bg-black/90 bg-bgDesign2">
        <div className="transparentWhite text-center text-6xl font-extrabold">
          Social Network
        </div>
        <div className="text-5xl text-center mt-4 mb-8 font-bold text-white">
          Visit Our Facebook Page
        </div>
        <div className="flex justify-center items-center">
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fp%2FDynamic-Coaches-61557383070808%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            style={{
              border: 'none',
              overflow: 'hidden',
              maxWidth: '340px',
              width: '100%',
              height: '500px',
            }}
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Home;
