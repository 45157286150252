import React from 'react';
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTheme } from '@mui/material';
const MaterialPhone = ({ country, onChange, value, height, fontSize, ...props }) => {
  const theme = useTheme();
  const handlePhoneChange = (phone, data) => {
    let formattedPhone = phone;
    const dialcode = data?.dialCode;
    //exclude start dialcode string
    if (dialcode) {
      let excludeDialCode = phone?.replace(dialcode, '');
      if (excludeDialCode?.[0] === '0' || excludeDialCode?.[0] === 0) {
        //remove the zero
        excludeDialCode = excludeDialCode?.slice(1);
        formattedPhone = `${dialcode}${excludeDialCode}`;
      }
    }
    onChange(formattedPhone);
  };
  return (
    <PhoneNumber
      height={height}
      fontSize={fontSize}
      country={country || 'gb'}
      onChange={handlePhoneChange}
      value={value}
      countryCodeEditable={false}
      theme={theme}
      {...props}

      specialLabel=''
    />
  );
};
export default MaterialPhone;
const PhoneNumber = styled(PhoneInput)`
  input:focus {
    box-shadow: transparent !important;
    outline:none !important;
  }
  .form-control {
    width: 100% !important;
    background-color: transparent;
    color: #0B0B0B;
    font-weight: 400;
    border: none;
    border-bottom:1px solid gray !important;
    border-radius: 0;
  }
  .react-tel-input, .form-control:focus {
    box-shadow: none !important;
  }
   .form-control:focus-within {
        border-bottom:1px solid orange !important;
    }
    .form-control, .react-tel-input {
    padding: 4px 4px 4px 58px !important;
    }
  .special-label {
    margin-left: 4px;
    color: #606060;
    }
`;