import { responseApi } from "use-hook-api"


const uid = process.env.NEXT_PUBLIC_UID

const emailURL = process.env.NEXT_PUBLIC_EMAIL_URL



export const sendEmailApi = (data) => {
    return responseApi(`${emailURL}api/email/`, 'post', data, { uid })
}
